<template>
  <div class="page">
    <section>
      <div class="box">
        <span class="title">参与成功</span>
        <img class="banner" src="@image/cycg-banner.png" alt="" />
        <span class="prompt"
          >将会有人进行审核，审核通过将会有礼金打入您的账户</span
        >
        <div class="group">
          <span>如未收到礼金请电话联系我们：</span>
          <span class="phone">4009201081</span>
        </div>
      </div>
      <span class="subTitle"
        >特此声明:根据《中华人民共和国个人所得税法实施条例》国令第 707号)
        第六条第(二)项的规定，我们将为您代扣代缴本次调研礼
        金个税，您的本次调研实际收入不会减少。</span
      >
      <span @click="$router.push({ path: '/research' })" class="button"
        >进入更多调研</span
      >
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.page {
  > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 550px;
      height: 620px;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px rgba(3, 219, 198, 0.2);
      margin: 50px 0 30px 0;
      font-size: 24px;
      color: $--themeColor;
      padding: 49px 83px;
      box-sizing: border-box;
      > .title {
        font-weight: bold;
      }
      > .banner {
        width: 300px;
        height: 226px;
        object-fit: cover;
        margin: 100px 0 123px 0;
      }
      > .duration {
        font-size: 24px;
        color: #333;
        margin-bottom: 30px;
      }
      > .money {
        font-size: 36px;
        margin-bottom: 20px;
      }
      > .prompt {
        font-size: 16px;
        color: $--themeColor;
      }
      > .group {
        display: block;
        font-size: 14px;
        color: #8f8f8f;
        margin-top: 10px;
        > .phone {
          color: #333333;
          text-decoration: underline;
          margin-left: auto;
        }
      }
    }
    > .subTitle {
      font-size: 20px;
      color: #333333;
      line-height: 1.8;
      width: 565px;
      margin-bottom: 20px;
    }
    > .button {
      cursor: pointer;
      width: 384px;
      height: 46px;
      background: $--themeColor;
      border-radius: 23px;
      color: #ffffff;
      font-size: 15px;
      text-align: center;
      line-height: 46px;
    }
  }
}
</style>